import { FC, Fragment, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Typography, Link as MuiLink, SxProps } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { PropsColors } from '@Base/theme';
import {
  ApartmentItemStatusColorConstants,
  ApartmentItemStatusIconConstants,
  ApartmentItemStatusTypes,
} from '@Base/constants/apartment';

type IconPositionType = 'start' | 'end';

interface LinkButtonProps {
  path?: string;
  label: string | ReactNode;
  id?: string;
  active?: boolean;
  variant?: string;
  iconComponent?: ReactNode;
  iconPosition?: IconPositionType;
  sx?: SxProps;
  status?: ApartmentItemStatusTypes;
  color?: PropsColors;
  onClick?: $TSFixMeFunction;
  deliveryCheckbox?: ReactNode;
  classNameProp?: string;
  item_description?: string;
}

const LinkButton: FC<LinkButtonProps> = (props: LinkButtonProps) => {
  const { label, id, sx, path, status, onClick, classNameProp } = props;
  const location = useLocation();
  const variant: $TSFixMe = props.variant || 'outlined';
  const iconPosition: IconPositionType = props.iconPosition || 'end';
  const color = status ? ApartmentItemStatusColorConstants[status] : undefined;
  const active = status ? !!ApartmentItemStatusColorConstants[status] : false;
  const iconComponent =
    props.iconComponent ||
    (status ? (
      ApartmentItemStatusIconConstants[status]
    ) : (
      <KeyboardArrowRightIcon />
    ));

  const renderButton: ReactNode = (
    props.item_description ? (
      <Button
        variant={active ? 'outlinedExtra' : variant}
        startIcon={iconPosition === 'start' ? iconComponent : undefined}
        endIcon={iconPosition === 'end' ? iconComponent : undefined}
        sx={{ display: 'block', width: '100%', maxHeight: 'fit-content', height: '100%', ...(sx ? sx : {}) }}
        color={color}
      >
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: '30px', lineHeight: 'normal' }} >
          {typeof label === 'string' ? (
            <Typography variant="title" align="left" width={'100%'}>
              {label}
            </Typography>
          ) : (
            label
          )}
          {props.deliveryCheckbox ? props.deliveryCheckbox : null}
        </div>

        <div style={{ width: '100%', display: 'block', textAlign: 'left', height: 'auto', lineHeight: 'normal' }} >
          {props.item_description && ( <Typography variant="body2" align="left" width={'100%'}>
            {props.item_description}
          </Typography>)}
        </div>
      </Button>
    ) : (
      <Button
        variant={active ? 'outlinedExtra' : variant}
        startIcon={iconPosition === 'start' ? iconComponent : undefined}
        endIcon={iconPosition === 'end' ? iconComponent : undefined}
        sx={{ width: '100%', ...(sx ? sx : {}) }}
        color={color}
      >
        {typeof label === 'string' ? (
          <Typography variant="title" align="left" width={'100%'}>
            {label}
          </Typography>
        ) : (
          label
        )}

        {props.deliveryCheckbox ? props.deliveryCheckbox : null}
      </Button>
    )
  );

  return (
    <Fragment>
      {onClick ? (
        <MuiLink
          style={{ textDecoration: 'none' }}
          onClick={() => {
            onClick(id);
          }}
          className={classNameProp}
        >
          {renderButton}
        </MuiLink>
      ) : (
        <Link
          id={id}
          to={{ pathname: path || '' }}
          state={{ from: location.pathname }}
          style={{ textDecoration: 'none' }}
          onClick={onClick}
          className={classNameProp}
        >
          {renderButton}
        </Link>
      )}
    </Fragment>
  );
};

export default LinkButton;
